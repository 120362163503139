import React from 'react'
import SEO from '~/components/seo'
import ProductGrid from '../components/ProductGrid'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import { Head, Text, TextContainer } from '../styles/productListPage'
import Scale from '../components/Scale'
import { graphql, useStaticQuery } from 'gatsby'
import PageTransitionWhite from '../components/Animation/PageTransitionWhite'

// TODO: name change: ProductListPage
const ItemListPage = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: { fields: [createdAt], order: DESC }
          filter: { productType: { eq: "雑貨" } }
        ) {
          edges {
            node {
              tags
              id
              title
              handle
              shopifyId
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )
  return (
    <>
      <SEO
        title="雑貨一覧"
        keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
      />
      <Head>
        <Image filename="photo/omamori.jpg" />
        <Scale />
        <TextContainer>
          <CategoryTitle en="Miscellaneous goods" label="雑貨" color="white" />
          <Text>
            高天原のコンセプトを雑貨の形で表現した
            <br />
            オリジナル商品。
          </Text>
        </TextContainer>
      </Head>
      <ProductGrid data={allShopifyProduct.edges} />
      <PageTransitionWhite />
    </>
  )
}

export default ItemListPage
